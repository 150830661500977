import React, { useEffect, Fragment, useReducer } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
 import { faSearch } from "@fortawesome/free-solid-svg-icons"

import MobileSearchInput from "./MobileSearch/MobileSearchInput"
import MobileSearchResults from "./MobileSearch/MobileSearchResults"

import styles from "./utils/search.module.scss"
import { fuzzySearch, groupBy } from "./services/fuzzySearch"
import { MobileSearchReducer } from "./services/reducers/MobileSearchReducer"

export const initialSearchState = {
  searchQuery: "",
  searchQueue: "",
  searchResults: [],
  isLoading: false,
}

const MobileSearch = ({
  inputRef,
  dataSource,
  onSelect,
  backPath,
  locationState,
  module,
  placeholder,
}) => {
  const [searchState, searchDispatch] = useReducer(MobileSearchReducer, {
    ...initialSearchState,
  })

  const { searchQuery, searchQueue, searchResults, isLoading } = searchState

  useEffect(() => {
    inputRef.current.focus()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })
    if (searchQuery) {
      searchDispatch({ type: "SET_IS_LOADING", payload: true })
      switch (dataSource.type) {
        case "api":
          break
        case "graphql":
          if (searchQueue) clearTimeout(searchQueue)
          searchDispatch({
            type: "SET_SEARCH_QUEUE",
            payload: setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )
              searchResults = groupBy(searchResults, "DrugName")
              await searchDispatch({
                type: "SET_SEARCH_RESULTS",
                payload: searchResults,
              })
              searchDispatch({
                type: "SET_IS_LOADING",
                payload: false,
              })
            }, 1000),
          })
          break
        case "epharmacy":
          if (searchQueue) clearTimeout(searchQueue)
          searchDispatch({
            type: "SET_SEARCH_QUEUE",
            payload: setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )
              await searchDispatch({
                type: "SET_SEARCH_RESULTS",
                payload: searchResults,
              })
              searchDispatch({
                type: "SET_IS_LOADING",
                payload: false,
              })
            }, 1000),
          })
          break
        default:
          break
      }
    }
    //eslint-disable-next-line
  }, [searchQuery])

  const handleValidateMatchDistance = () => {
    return searchResults.length > 0 && searchResults[0].distance !== 1
  }

  const isExactMatch = handleValidateMatchDistance()

  return (
    <Fragment>
      <MobileSearchInput
        isLoading={isLoading}
        backPath={backPath}
        locationState={locationState || {}}
      >
        <div className={classNames("field m-0 pr-1", styles["mobileSearch__input_field"])}>
          <p
            className={classNames("control has-icons-left", {
              "is-loading": isLoading,
            })}
          >
            <input
              type="text"
              ref={inputRef}
              placeholder={placeholder}
              className={classNames("input", styles["mobileSearch__input"])}
              onChange={(event) =>
                searchDispatch({
                  type: "SET_SEARCH_QUERY",
                  payload: event.target.value,
                })
              }
              value={searchQuery}
            />
            <span className={classNames("icon is-small is-left")}>
              <FontAwesomeIcon icon={faSearch} className={classNames("has-text-primary")} />
            </span>
          </p>
        </div>
      </MobileSearchInput>
      <MobileSearchResults
        searchResults={searchResults}
        isExactMatch={isExactMatch}
        onSelect={onSelect}
        searchDispatch={searchDispatch}
        module={module}
      />
    </Fragment>
  )
}

export default MobileSearch
