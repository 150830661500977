import React, { useRef, Fragment, useContext } from "react"
import { navigate } from "gatsby"

import MobileSearch from "../Search/MobileSearch"
import AddMedicineModal from "./AddMedicineModal"

import { AppContext } from "../../context/AppContext"
import { isMedicineAlreadyInCart } from "./services/cart"
import useNovartisCoupons from "./Order/hooks/useNovartisCoupons"
import useAssistanceCalculatorImages from "./Order/hooks/useAssistanceCalculatorImages"

const SearchMedsMobile = ({
  pageContext,
  data,
  lookupKeys,
  searchLabel,
  moduleState,
  moduleDispatch,
}) => {
  const { module } = pageContext
  const moduleName = module?.name
  const novartisCoupons = useNovartisCoupons()
  const imageData = useAssistanceCalculatorImages()
  const assistanceCalculatorImage =
    imageData?.calculatorIcon?.childImageSharp?.fixed
  const { dispatch } = useContext(AppContext)
  const inputRef = useRef({ current: { value: "" } })

  const handleOnSelect = (medicine) => {
    if (isMedicineAlreadyInCart(medicine, moduleState.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${medicine.DrugName} already in cart`,
          color: "danger",
        },
      })
    else
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Add medicine to cart",
          isCard: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: (
            <AddMedicineModal
              addToCart={addMedicineToCart}
              medicine={medicine}
              dispatch={dispatch}
              moduleName={moduleName}
              coupons={novartisCoupons}
              image={assistanceCalculatorImage}
            />
          ),
        },
      })
  }

  const searchComponentProps = {
    placeholder: "Search medicines (i.e. Sacubitril-Valsartan (Entresto))",
    label: searchLabel,
    dataSource: {
      type: "epharmacy",
      data: data,
      lookupKeys: lookupKeys,
    },
    module: "epharmacy",
    onSelect: handleOnSelect,
    inputRef: inputRef,
    backPath: pageContext.backPath,
  }

  const addMedicineToCart = async (item, qty, orderDetails) => {
    const medicineList = [...moduleState.medicines]
    const { totalQuantity, assistanceQuantity, orderTotal } = orderDetails
    medicineList.push({
      ...item,
      qty: qty,
      totalQuantity: totalQuantity,
      assistanceQuantity: assistanceQuantity,
      orderTotal: orderTotal,
    })
    await moduleDispatch({
      type: "SAVE_MEDICINES",
      payload: medicineList,
    })

    navigate(pageContext.backPath, {
      state: {
        selectedMedicine: item.DrugName,
      },
    })
  }

  return (
    <Fragment>
      <MobileSearch {...searchComponentProps} />
    </Fragment>
  )
}

export default SearchMedsMobile
