import React from "react"
import classNames from "classnames"

import RxIndicator from "elements/RxIndicator"

import styles from "../utils/search.module.scss"
import { generatePrice } from "../../Epharmacy/services/computations"

const BrandTag = ({ values }) => {
  if (Array.isArray(values) && values.length > 0) {
    return (
      <span className="help">
        Sample Brands: {values?.map((value) => value?.Brand[0]).join(", ")}
      </span>
    )
  } else {
    return <span className="help">Sample Brand: {values?.Brand[0]}</span>
  }
}

const MobileSearchResults = ({
  searchResults,
  onSelect,
  searchDispatch,
  module,
}) => {
  return (
    <section className={classNames(styles["mobileSearch__results"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {searchResults.slice(0, 20).map((result) => (
          <li
            className="is-flex is-flex-direction-column"
            onClick={() => {
              if (onSelect) onSelect(result)
              searchDispatch({ type: "SET_SEARCH_QUERY", payload: "" })
              searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })
            }}
            role="button"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                if (onSelect) onSelect(result)
                searchDispatch({ type: "SET_SEARCH_QUERY", payload: "" })
                searchDispatch({ type: "SET_SEARCH_RESULTS", payload: [] })
              }
            }}
            tabIndex={0}
          >
            <div className="is-flex is-flex-direction-row has-text-weight-bold is-justify-content-space-between is-align-items-center">
              <div className="is-size-6">{result?.DrugName}</div>
              <div>
                <RxIndicator />
              </div>
            </div>
            <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
              <div className="is-size-7">{result?.brand}</div>
              {module === "epharmacy" && (
                <div className="is-size-7">Php {generatePrice(result)}</div>
              )}
            </div>
          </li>
        ))}
      </ol>
    </section>
  )
}

export default MobileSearchResults
