import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import styles from "../utils/search.module.scss"

const MobileSearchInput = ({
  children,
  backPath,
  locationState,
}) => (
  <section className={classNames(styles["mobileSearch"])}>
    <div className={classNames("is-flex", styles["mobileSearch__container"])}>
      {children}
      <Link to={backPath} state={{ ...locationState }}>
        <button
          className={classNames(styles["mobileSearch__button"])}
        >
          Cancel
        </button>
      </Link>
    </div>
  </section>
)

export default MobileSearchInput
