import React, { useContext } from "react"

import SearchMedsMobile from "../SearchMedsMobile"
import Layout from "layout"

import useNovartisProducts from "./hooks/useNovartisProducts"
import { EpharmacyContext } from "../EpharmacyContext/EpharmacyContext"

const EpharmacyMobile = ({ pageContext, location }) => {
  const data = useNovartisProducts()
  let dataMap = data.allAirtableallNovartisProducts.nodes.map(
    (node) => node.data
  )

  // transform data to fit search format
  dataMap = dataMap.map(parseAirtableData)

  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)

  return (
    <Layout
      title=""
      subtitle=""
      process="epharmacy"
      seoTitle="Search Medicines"
      display={{ footer: false, helpCenterBanner: false }}
      path={location?.pathname}
    >
      <SearchMedsMobile
        pageContext={pageContext}
        data={dataMap}
        lookupKeys={["brand", "DrugName"]}
        searchLabel="Medicine Search"
        moduleState={epharmacyState}
        moduleDispatch={epharmacyDispatch}
      />
    </Layout>
    
  )
}

const parseAirtableData = (element) => {
  let jsonFormat = {
    brand: [element?.brand],
    DrugName: element?.drugName,
    productTitle: element?.productTitle,
    form: element?.form?.[0],
    molecule: element?.molecule?.[0],
    strength: element?.strength?.[0],
    price_list: {
      unitPrice: element?.unitPrice?.[0],
      vat: element?.vat?.[0],
      vatex_unit_price: element?.vatexUnitPrice?.[0],
    },
  }

  return jsonFormat
}

export default EpharmacyMobile
